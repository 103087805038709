import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpService } from '../common/http.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-kompaktne-vahy',
  templateUrl: './kompaktne-vahy.component.html',
  styleUrls: ['./kompaktne-vahy.component.scss']
})
export class KompaktneVahyComponent implements OnInit, AfterViewInit {
  

  categories = [];
  selectedCategory = {
    ID: 0,
    nazov: undefined
  };
  scales = [];

  constructor(private httpService: HttpService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    this.httpService.getCompactCategories()
      .subscribe(res => {
        this.categories = res.kompaktne_kategorie;
        this.selectedCategory = this.categories[0];
        
      });
      
  }

}
