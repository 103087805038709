import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-hutny",
  templateUrl: "./hutny.component.html",
  styleUrls: ["./hutny.component.scss"]
})
export class HutnyComponent implements OnInit {
  constructor(private titleService: Title, private meta: Meta) {}

  ngOnInit() {
    this.titleService.setTitle(
      "Hutný materiál - elektronické váhy a systémy | Wesico "
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Hutný materiál - plechy, betonárska oceľ, zvárané siete, oceľové rúry, jaklové profily, profilová oceľ."
    });
  }
}
