import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { HttpService } from 'src/app/common/http.service.js';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-kompaktne-detail',
  templateUrl: './kompaktne-detail.component.html',
  styleUrls: ['./kompaktne-detail.component.scss']
})
export class KompaktneDetailComponent implements OnInit, AfterViewInit {


  scales = [];
  category;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private titleService: Title,
    private meta: Meta
  ) {
    console.log("RELOAD");
    //import('../../common/custom-grid.js');
  }

  ngOnInit() {
    console.log("ROUTE", this.route);
    this.httpService.getCategoryByUrl(this.route.routeConfig.path)
      .subscribe(res => {
        this.category = res.kompaktne_kategorie[0];
        this.titleService.setTitle(
          `${this.category.nazov} - Wesico`
        );
        this.meta.updateTag({
          name: "description",
          content:
            `${this.category.description}`
        });
        this.loadScales(this.category.ID);
      })
  }

  loadScales(id) {
    this.httpService.getCompactScalesForCategory(id)
      .subscribe(res => {
        this.scales = res.kompaktne_vahy;
        setTimeout(() => {

          this.expandingGrid($('.expanding-grid'), {});
        }, 500)

      })
  }



  expandingGrid(context, options) {
    console.log('EXPANDING', context);

    var getLastSiblingInRow = function (element) {
      var candidate = element,
        elementTop = element.offsetTop;

      // Loop through the element’s next siblings and look for the first one which
      // is positioned further down the page.
      while (candidate.nextElementSibling !== null) {
        if (candidate.nextElementSibling.offsetTop > elementTop) {
          return candidate;
        }
        candidate = candidate.nextElementSibling;
      }
      return candidate;
    }

    var defaults = {
      animationDuration: 250,
      linksSelector: '.links a',
      expandingAreaSelector: '.expanding-container',
      closeButtonMarkup: '<a href="#" class="close-button">Close</a>',
      spacerMarkup: '<span class="spacer" aria-hidden="true"/>',
      elementActiveClass: 'active',
      elementExpandedClass: 'expanded',
      onExpandBefore: false,
      onExpandAfter: false
    };

    var settings = $.extend({}, defaults, options);

    var isExpanded = false;
    var activeLink;
    var activeExpandedArea;
    var activeExpandedAreaTop;
    var activeExpandedAreaHeight;
    var lastItemInActiveRow;
    var activeRowChanged = false;
    var checkExpandedAreaResize;
    var $links = $('.links a');
    var $expandingAreas = $('.expanding-container');
    var $closeButton = $(settings.closeButtonMarkup);
    var $spacer = $(settings.spacerMarkup);
    var $secondarySpacer = $spacer.clone();
    var $listItems = $('.links li');
    $listItems.each(function () {

      var cw = $(this).width();
      console.log(this, cw);
      $(this).css({ 'height': cw + 'px' });
    });
    console.log('CLICK', $links);
    // Process the links.
    $links.each(function () {

      var $this = $(this);
      var targetId = $this.attr('name').match(/#([^\?]+)/)[1];
      var target = document.getElementById(targetId);

      if (target) {
        $this.click(function (event) {

          var clickedLink = this;
          var scrollTargetOffset;
          var closeButtonAnimationDelay;

          event.preventDefault();

          // Is this link already expanded?
          if (isExpanded && activeLink === clickedLink) {
            // Close it.
            $closeButton.click();
          }
          // Otherwise, expand it.
          else {
            $links.removeClass(settings.elementActiveClass).filter($this).addClass(settings.elementActiveClass).parent('li').each(function () {
              var lastSibling = getLastSiblingInRow(this);
              activeRowChanged = lastSibling !== lastItemInActiveRow;
              if (activeRowChanged) {
                lastItemInActiveRow = lastSibling;
              }
              // If we are changing rows, replace spacer with secondary spacer.
              if (isExpanded && activeRowChanged) {
                $secondarySpacer.height($spacer.height());
                $spacer.height(0).replaceWith($secondarySpacer);
              }
              $(lastItemInActiveRow).after($spacer);
            });
            if (isExpanded && activeRowChanged) {
              $secondarySpacer.animate({ height: 0 }, settings.animationDuration, function () {
                $(this).detach();
              });
              $closeButton.removeClass(settings.elementActiveClass).hide();
            }
            scrollTargetOffset = ($secondarySpacer.position().top < $spacer.position().top ? $secondarySpacer.height() : 0);
            activeExpandedAreaTop = ($spacer.position().top - scrollTargetOffset);
            $expandingAreas.removeClass(settings.elementExpandedClass).hide().filter(target).each(function () {
              var $this = $(this);
              var autoHeight = $this.height();
              var autoOuterHeight = $this.outerHeight();
              var initialHeight = (isExpanded && activeExpandedAreaHeight && (activeRowChanged === false)) ? activeExpandedAreaHeight : 0;

              stopExpandedAreaMonitor();

              $spacer.animate({ height: autoHeight + 'px' }, settings.animationDuration);

              $this.css({
                height: initialHeight + 'px',
                position: 'absolute',
                left: 0,
                top: $spacer.position().top + 'px'
              }).show(0, function () {
                // Callback.
                if (typeof settings.onExpandBefore === 'function') {
                  settings.onExpandBefore.call(this);
                }
              }).animate({
                height: autoHeight + 'px',
                top: activeExpandedAreaTop + 'px'
              }, settings.animationDuration, function () {
                $this.css({ height: 'auto' }).addClass(settings.elementExpandedClass);

                // Set a timer to monitor changes to expanded area’s height.
                activeExpandedAreaHeight = $this.height();
                checkExpandedAreaResize = setInterval(function () {
                  var activeExpandedAreaNewHeight = $this.height();
                  if (activeExpandedAreaNewHeight !== activeExpandedAreaHeight) {
                    activeExpandedAreaHeight = activeExpandedAreaNewHeight;
                    syncExpandedAreaWithSpacer();
                  }
                }, 1000);

                // Callback.
                if (typeof settings.onExpandAfter === 'function') {
                  settings.onExpandAfter.call(this);
                }
              });

              // Scroll the page to bring the active link and preview into view.
              var scrollTargetTop = $(clickedLink).offset().top - scrollTargetOffset;
              var scrollTargetBottom = $this.offset().top + autoOuterHeight + 20 - scrollTargetOffset;
              //scrollSectionIntoView(scrollTargetTop, scrollTargetBottom);
            });

            // Activate close button.
            closeButtonAnimationDelay = (isExpanded && activeRowChanged && ($this.parent().index() > $(activeLink).parent().index())) ? settings.animationDuration : (settings.animationDuration / 4);
            $closeButton.css({
              position: 'absolute',
              right: 0,
              top: activeExpandedAreaTop + 'px'
            }).delay(closeButtonAnimationDelay).fadeIn(settings.animationDuration, function () {
              $(this).addClass(settings.elementActiveClass);
            });

            // Set global variables.
            activeLink = this;
            activeExpandedArea = target;
            isExpanded = true;
          }
        });
      }
    });

    // Process the close button.
    $closeButton.appendTo(context).hide().click(function (event) {
      var $activeLink = $(activeLink);
      var activeLinkTopOffset = $activeLink.offset().top;
      var activeLinkBottomOffset = activeLinkTopOffset + $activeLink.outerHeight();

      event.preventDefault();

      // DOM manipulation and animations.
      $links.removeClass(settings.elementActiveClass);
      $expandingAreas.slideUp(settings.animationDuration).removeClass(settings.elementExpandedClass);
      $closeButton.removeClass('active').hide();
      $spacer.animate({ height: 0 }, settings.animationDuration, function () {
        $spacer.detach();
      });

      // Scroll the page to bring the active link into view.
      //scrollSectionIntoView(activeLinkTopOffset, activeLinkBottomOffset);

      stopExpandedAreaMonitor();

      // Reset global variables.
      isExpanded = false;
      activeLink = false;
      activeExpandedArea = false;
    });

    /**
     * Stop monitoring size of expanded area.
     */
    var stopExpandedAreaMonitor = function () {
      if (checkExpandedAreaResize) {
        clearInterval(checkExpandedAreaResize);
      }
    };

    /**
     * Match preview and spacer in height and position.
     */
    var syncExpandedAreaWithSpacer = function () {
      if (activeExpandedArea && isExpanded) {
        $spacer.height($(activeExpandedArea).height());
        activeExpandedAreaTop = $spacer.position().top;
        $closeButton.add(activeExpandedArea).css({ top: activeExpandedAreaTop + 'px' });
      }
    };

    /**
     * Place spacer in proper position within grid.
     */
    var positionSpacer = function () {
      var lastSibling;
      if (activeLink && lastItemInActiveRow && isExpanded) {
        // Remove spacer.
        $spacer.detach();
        lastSibling = getLastSiblingInRow($(activeLink).parent()[0]);
        // Reposition spacer, if necessary.
        if (lastItemInActiveRow !== lastSibling) {
          console.log(lastSibling);
          lastItemInActiveRow = lastSibling;
        }
        // Restore spacer.
        $(lastItemInActiveRow).after($spacer);
      }
    };

    // React to window resize.
    $(window).resize(function () {
      if (isExpanded) {
        positionSpacer();
        syncExpandedAreaWithSpacer();
      }
    });

  }


  ngAfterViewInit(): void {

  }
}
