import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-vahy',
  templateUrl: './vahy.component.html',
  styleUrls: ['./vahy.component.scss']
})
export class VahyComponent implements OnInit {

  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(
      "Elektronické váhy a systémy - Wesico "
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Elektronické váhy a systémy, vývoj, výroba a predaj = plošinové, cestné, koľajové, kompaktné váhy pre všetky odvetvia priemyslu."
    });
  }

}
