import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HutnyComponent } from './hutny/hutny.component';
import { IntroComponent } from './intro/intro.component';
import { VahyComponent } from './vahy/vahy.component';
import { PriemyselneVahyComponent } from './priemyselne-vahy/priemyselne-vahy.component';
import { KompaktneVahyComponent } from './kompaktne-vahy/kompaktne-vahy.component';
import { ReferencieComponent } from './referencie/referencie.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { PlosinoveComponent } from './priemyselne-vahy/plosinove/plosinove.component';
import { CestneComponent } from './priemyselne-vahy/cestne/cestne.component';
import { KolajoveComponent } from './priemyselne-vahy/kolajove/kolajove.component';
import { ZasobnikoveComponent } from './priemyselne-vahy/zasobnikove/zasobnikove.component';
import { KompaktneDetailComponent } from './kompaktne-vahy/kompaktne-detail/kompaktne-detail.component';


const routes: Routes = [
  { path: '', component: IntroComponent },
  { path: 'hutny-material', component: HutnyComponent },
  { path: 'vahy', component: VahyComponent },
  { path: 'priemyselne-vahy', component: PriemyselneVahyComponent },
  { path: 'priemyselne-vahy/cestne', component: CestneComponent },
  { path: 'priemyselne-vahy/kolajove', component: KolajoveComponent },
  { path: 'priemyselne-vahy/zasobnikove', component: ZasobnikoveComponent },
  { path: 'priemyselne-vahy/plosinove', component: PlosinoveComponent },
  {
    path: 'kompaktne-vahy', component: KompaktneVahyComponent,
    children: [
      { path: 'gastro', component: KompaktneDetailComponent },
      { path: 'obchodne', component: KompaktneDetailComponent },
      { path: 'univarzalne', component: KompaktneDetailComponent },
      { path: 'pocitacie', component: KompaktneDetailComponent },
      { path: 'plosinove', component: KompaktneDetailComponent },
      { path: 'zavesne', component: KompaktneDetailComponent },
      { path: 'paletove', component: KompaktneDetailComponent },
      { path: 'ulove', component: KompaktneDetailComponent },
      { path: 'pre-domacnost', component: KompaktneDetailComponent },
    ]
  },
  { path: 'referencie', component: ReferencieComponent },
  { path: 'kontakty', component: KontaktComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
