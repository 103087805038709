import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zasobnikove',
  templateUrl: './zasobnikove.component.html',
  styleUrls: ['./zasobnikove.component.scss']
})
export class ZasobnikoveComponent implements OnInit {

  galleryOptions;
  images = [];

  constructor() {
    this.images = [
      {
        small: `assets/zasobnikove/zasobnikova1.jpg`,
        medium: `assets/zasobnikove/zasobnikova1.jpg`,
        big: `assets/zasobnikove/zasobnikova1.jpg`
      },
      {
        small: `assets/zasobnikove/zasobnikova2.jpg`,
        medium: `assets/zasobnikove/zasobnikova2.jpg`,
        big: `assets/zasobnikove/zasobnikova2.jpg`
      },
      {
        small: `assets/zasobnikove/zasobnikova3.jpg`,
        medium: `assets/zasobnikove/zasobnikova3.jpg`,
        big: `assets/zasobnikove/zasobnikova3.jpg`
      },
      {
        small: `assets/zasobnikove/zasobnikova4.jpg`,
        medium: `assets/zasobnikove/zasobnikova4.jpg`,
        big: `assets/zasobnikove/zasobnikova4.jpg`
      },
      {
        small: `assets/zasobnikove/zasobnikova5.jpg`,
        medium: `assets/zasobnikove/zasobnikova5.jpg`,
        big: `assets/zasobnikove/zasobnikova5.jpg`
      },
      {
        small: `assets/zasobnikove/zasobnikova6.jpg`,
        medium: `assets/zasobnikove/zasobnikova6.jpg`,
        big: `assets/zasobnikove/zasobnikova6.jpg`
      },
      {
        small: `assets/zasobnikove/zasobnikova7.jpg`,
        medium: `assets/zasobnikove/zasobnikova7.jpg`,
        big: `assets/zasobnikove/zasobnikova7.jpg`
      }
    ]
    this.galleryOptions = [
      { "image": false, width: '800px', height: '250px', "thumbnailsColumns": 4, "thumbnailsRows": 2, "thumbnailsPercent": 40, "imagePercent": 60, "thumbnailMargin": 2, "thumbnailsMargin": 2 },
      { "breakpoint": 500, "width": "300px", "height": "300px", "thumbnailsColumns": 4 },
      { "breakpoint": 300, "width": "100%", "height": "200px", "thumbnailsColumns": 4 }

    ];
  }

  ngOnInit() {
  }

}
