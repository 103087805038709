import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { FotoDialogComponent } from 'src/app/common/foto-dialog/foto-dialog.component';

@Component({
  selector: 'app-cestne',
  templateUrl: './cestne.component.html',
  styleUrls: ['./cestne.component.scss']
})
export class CestneComponent implements OnInit {

  galleryOptions;
  images = [];
  showBouncer = true;

  constructor(private titleService: Title,
    private meta: Meta, public el: ElementRef, private dialog: MatDialog) {
    this.images = [
      {
        small: `assets/cestne/cestne1.jpg`,
        medium: `assets/cestne/cestne1.jpg`,
        big: `assets/cestne/cestne1.jpg`
      },
      {
        small: `assets/cestne/cestne2.jpg`,
        medium: `assets/cestne/cestne2.jpg`,
        big: `assets/cestne/cestne2.jpg`
      },
      {
        small: `assets/cestne/cestne3.jpg`,
        medium: `assets/cestne/cestne3.jpg`,
        big: `assets/cestne/cestne3.jpg`
      },
      {
        small: `assets/cestne/cestne4.jpg`,
        medium: `assets/cestne/cestne4.jpg`,
        big: `assets/cestne/cestne4.jpg`
      },
      {
        small: `assets/cestne/cestne5.jpg`,
        medium: `assets/cestne/cestne/cestne5.jpg`,
        big: `assets/cestne/cestne5.jpg`
      },
      {
        small: `assets/cestne/cestne6.jpg`,
        medium: `assets/cestne/cestne6.jpg`,
        big: `assets/cestne/cestne6.jpg`
      },
      {
        small: `assets/cestne/cestne7.jpg`,
        medium: `assets/cestne/cestne7.jpg`,
        big: `assets/cestne/cestne7.jpg`
      },
      {
        small: `assets/cestne/cestne8.jpg`,
        medium: `assets/cestne/cestne8.jpg`,
        big: `assets/cestne/cestne8.jpg`
      },
      {
        small: `assets/cestne/cestne9.jpg`,
        medium: `assets/cestne/cestne9.jpg`,
        big: `assets/cestne/cestne9.jpg`
      },
      {
        small: `assets/cestne/cestne10.jpg`,
        medium: `assets/cestne/cestne/cestne10.jpg`,
        big: `assets/cestne/cestne10.jpg`
      },
      {
        small: `assets/cestne/cestne11.jpg`,
        medium: `assets/cestne/cestne11.jpg`,
        big: `assets/cestne/cestne11.jpg`
      },
      {
        small: `assets/cestne/cestne12.jpg`,
        medium: `assets/cestne/cestne/cestne12.jpg`,
        big: `assets/cestne/cestne12.jpg`
      },
      {
        small: `assets/cestne/cestne13.jpg`,
        medium: `assets/cestne/cestne13.jpg`,
        big: `assets/cestne/cestne13.jpg`
      },
      {
        small: `assets/cestne/cestne14.jpg`,
        medium: `assets/cestne/cestne14.jpg`,
        big: `assets/cestne/cestne14.jpg`
      }
    ]
    this.galleryOptions = [
      { image: false, width: '800px', height: '400px', "thumbnailsColumns": 4, "thumbnailsRows": 4, "thumbnailsPercent": 40, "imagePercent": 60, "thumbnailMargin": 2, "thumbnailsMargin": 2 },
      { "breakpoint": 500, "width": "300px", "height": "300px", "thumbnailsColumns": 3 },
      { "breakpoint": 300, "width": "100%", "height": "200px", "thumbnailsColumns": 2 }

    ];
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition = this.el.nativeElement.offsetTop
    const scrollPosition = window.pageYOffset
    if (scrollPosition > 350) {
      this.showBouncer = false;
    } else {
      this.showBouncer = true;
    }
  }

  ngOnInit() {
    this.titleService.setTitle(
      `Cestné váhy - Wesico`
    );
    this.meta.updateTag({
      name: "description",
      content:
        `Cestné váhy pre výrobné a obchodné prevádzky, prevádzky súvisiace s ťažbou nerastov, ukladanie a spracovanie odpadov.`
    });
  }

  zoomFoto(url) {
    this.dialog.open(FotoDialogComponent, {
      data: {
        url: url
      }
    });
  }

}
