import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-foto-dialog',
  templateUrl: './foto-dialog.component.html',
  styleUrls: ['./foto-dialog.component.scss']
})
export class FotoDialogComponent implements OnInit {

  url = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.url = data.url;
  }
  ngOnInit() {
  }

}
