import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpService {
    constructor(private httpClient: HttpClient) { }

    getAllReferences(): Observable<any> {
        return this.httpClient.get('/api/api.php/references?transform=1&filter=gallery,eq,1')
            .pipe();
    }

    getReferencesForCategory(categoryId): Observable<any> {
        return this.httpClient.get('/api/api.php/references?transform=1&filter=id_kategoria,eq,' + categoryId)
            .pipe();
    }

    getReferenceCategories(): Observable<any> {
        return this.httpClient.get('/api/api.php/referencie_kategorie?transform=1')
            .pipe();
    }

    getCompactCategories(): Observable<any> {
        return this.httpClient.get('/api/api.php/kompaktne_kategorie?transform=1&filter')
            .pipe();
    }

    getCategoryByUrl(url): Observable<any> {
        return this.httpClient.get('/api/api.php/kompaktne_kategorie?transform=1&filter=url,eq,' + url)
            .pipe();
    }

    getCompactScalesForCategory(categoryId): Observable<any> {
        return this.httpClient.get('/api/api.php/kompaktne_vahy?transform=1&filter=id_kategoria,eq,' + categoryId)
            .pipe();
    }

    sendEmail(text) {
        return this.httpClient.post('/api/mail.php', text)
            .pipe()
    }

    getImagesForReference(referenceId): Observable<any> {
        return this.httpClient.get('/api/api.php/referencie_obrazky?transform=1&filter=id_referencia,eq,' + referenceId)
        .pipe();
    }

}