import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HutnyComponent } from './hutny/hutny.component';
import { IntroComponent } from './intro/intro.component';
import { VahyComponent } from './vahy/vahy.component';
import { PriemyselneVahyComponent } from './priemyselne-vahy/priemyselne-vahy.component';
import { KompaktneVahyComponent } from './kompaktne-vahy/kompaktne-vahy.component';
import { ReferencieComponent } from './referencie/referencie.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PlosinoveComponent } from './priemyselne-vahy/plosinove/plosinove.component';
import { KolajoveComponent } from './priemyselne-vahy/kolajove/kolajove.component';
import { CestneComponent } from './priemyselne-vahy/cestne/cestne.component';
import { ZasobnikoveComponent } from './priemyselne-vahy/zasobnikove/zasobnikove.component';
import { HttpService } from './common/http.service';
import { HttpClientModule } from '@angular/common/http';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { KompaktneDetailComponent } from './kompaktne-vahy/kompaktne-detail/kompaktne-detail.component';
import { NgxGalleryModule } from 'ngx-gallery';
import 'hammerjs';
import { RefGalleryDialogComponent } from './referencie/ref-gallery-dialog/ref-gallery-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FotoDialogComponent } from './common/foto-dialog/foto-dialog.component';

export class CustomHammerConfig extends HammerGestureConfig  {
  overrides = {
      pinch: { enable: false },
      rotate: { enable: false }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HutnyComponent,
    IntroComponent,
    VahyComponent,
    PriemyselneVahyComponent,
    KompaktneVahyComponent,
    ReferencieComponent,
    KontaktComponent,
    NavbarComponent,
    PlosinoveComponent,
    KolajoveComponent,
    CestneComponent,
    ZasobnikoveComponent,
    KompaktneDetailComponent,
    RefGalleryDialogComponent,
    FotoDialogComponent
      ],
  imports: [
    ScrollToModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    NgxGalleryModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule
  ],
  entryComponents: [
    RefGalleryDialogComponent,
    FotoDialogComponent
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    HttpService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


