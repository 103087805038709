import { OnInit, Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from '../common/http.service';
import '../common/smtp.js'
declare let Email: any;
@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss']
})
export class KontaktComponent implements OnInit {

  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  // map: google.maps.Map;
  // lat = 48.734350;
  // lng = 18.239470;
  // coordinates = new google.maps.LatLng(this.lat, this.lng);
  // mapOptions: google.maps.MapOptions = {
  //   center: this.coordinates,
  //   zoom: 17,
  // };
  // marker = new google.maps.Marker({
  //   position: this.coordinates,
  //   map: this.map,
  // });

  constructor(private httpService: HttpService) { }

  ngOnInit() {
  }

  mapInitializer() {
    // this.map = new google.maps.Map(this.gmap.nativeElement, 
    // this.mapOptions);
    // this.marker.setMap(this.map);
  }

  ngAfterViewInit() {
    this.mapInitializer();
  }

  onSendClick() {
    Email.send({
      Host: 'smtp.websupport.sk',
      Username: 'F1j0k',
      Password: 'Pipo880314',
      To: 'filip.badura@gmail.com',
      From: 'filip.badura@gmail.com',
      Subject: 'Kompaktne vahy',
      Body: 'Mam zaujem. s pozdravom. Filip'
    }).then(message => {
      console.log(message);
    });

  }
  // this.httpService.sendEmail('text')
  //   .subscribe(res => {
  //     console.log(res);
  //   })


}
