import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-priemyselne-vahy',
  templateUrl: './priemyselne-vahy.component.html',
  styleUrls: ['./priemyselne-vahy.component.scss']
})
export class PriemyselneVahyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
