import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-plosinove',
  templateUrl: './plosinove.component.html',
  styleUrls: ['./plosinove.component.scss']
})
export class PlosinoveComponent implements OnInit {

  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(
      "Plošinové váhy - Wesico "
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Plošinové váhy vhodné najmä do skladov alebo na váženie balíkov. Vyberte si z ponuky našich plošinových váh."
    });
  }
}
