import { Component, OnInit, Inject } from '@angular/core';
import { NgxGalleryAnimation } from 'ngx-gallery';
import { HttpService } from 'src/app/common/http.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ref-gallery-dialog',
  templateUrl: './ref-gallery-dialog.component.html',
  styleUrls: ['./ref-gallery-dialog.component.scss']
})
export class RefGalleryDialogComponent implements OnInit {

  galleryOptions;
  images = [];

  constructor(private httpService: HttpService, @Inject(MAT_DIALOG_DATA) public data) {
    this.galleryOptions = [
      {
        width: '1000px',
        height: '700px',
        imagePercent: 100,
        thumbnailsPercent: 15,
        thumbnailsMargin: 0,
        thumbnailMargin: 0,
        imageAnimation: NgxGalleryAnimation.Slide,
        thumbnailsRemainingCount: true,
        image: true,
        preview: false,
      }
    ];
  }

  ngOnInit() {
    this.httpService.getImagesForReference(this.data.refer.ID)
      .subscribe(res => {
        console.log(res);
        res.referencie_obrazky.forEach(img => {
          this.images.push(
            {
              small: `assets/uploads/${img.name}`,
              medium: `assets/uploads/${img.name}`,
              big: `assets/uploads/${img.name}`
            }
          )
        });
      });
  }

}
