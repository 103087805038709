import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HttpService } from '../common/http.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { RefGalleryDialogComponent } from './ref-gallery-dialog/ref-gallery-dialog.component';
import { NgxGalleryAnimation } from 'ngx-gallery';

@Component({
  selector: 'app-referencie',
  templateUrl: './referencie.component.html',
  styleUrls: ['./referencie.component.scss']
})
export class ReferencieComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['nazov', 'udaje', 'gallery'];
  references = [];
  dataSource: MatTableDataSource<any>;
  galleryOptions;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private httpService: HttpService, private changeDetectorRefs: ChangeDetectorRef, private dialog: MatDialog) {
    this.galleryOptions = [
      { "image": false, "thumbnailsRemainingCount": true, "height": "100px" },
      { "breakpoint": 500, "width": "100%", "thumbnailsColumns": 2 }
    ];
  }

  onPreviewClick(refer) {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig = {
      data: {
        refer
      }
    };
    let dialogRef = this.dialog.open(RefGalleryDialogComponent, dialogConfig);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    this.httpService.getReferenceCategories()
      .subscribe(categories => {
        console.log(categories.referencie_kategorie[0].ID);
        this.httpService.getAllReferences()
          .subscribe(res => {
            res.references.forEach(ref => {
              ref.images = [];
              this.httpService.getImagesForReference(ref.ID)
                .subscribe(res => {
                  res.referencie_obrazky.forEach(img => {
                    ref.images.push(
                      {
                        small: `assets/uploads/${img.name}`,
                        medium: `assets/uploads/${img.name}`,
                        big: `assets/uploads/${img.name}`
                      }
                    )
                  });
                });
            });

            this.references = res.references;
            this.dataSource = new MatTableDataSource(this.references);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          })
      })
  }

}
